import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Row, Card, Col, Collapse, Container, Button, Form, Table, Badge, Spinner, InputGroup } from 'react-bootstrap';
import UFiles from './UFiles'
import './Checker.css'
import TableToExcel from '../helpers/Excel';
import { getFormattedDate } from '../helpers/functions';


const NOTENAME = ["nsigned", "ntz", "npolice", "narmy", "nhealth", "nwork", "npen2", "npen4", "npen5", "nform", "nbank", "nadvise", "ndoc", "npermit", "nfamily", "nalready", "nextra"]
const HORAAH = ["usigned", "utz", "uhealth", "uwork", "upen2", "upen4", "upen5", "uform", "ubank", "uadvise", "udoc", "ufamily", "ualready", "uextra"]
const EZER = ["usigned", "utz", "upolice", "uarmy", "uhealth", "upen2", "upen5", "uform", "ubank", "upermit", "ufamily", "uextra"]
const HEBREW = {
    usigned: 'סט מסמכים מלא וחתום ע"י העובד, מנהל ביה"ס והמפקח  מטעם "בני יוסף".',
    utz: "צילום ת.ז. + ספח.",
    upolice: "אישור ממשטרת ישראל על העדר מניעה להעסקה במוסדות חינוך.",
    uarmy: 'ותק צבאי – (שירות צבאי, שירות לאומי, שירות אזרחי) יש להעלות אשור על תקופת שירות בחודשים חתום על ידי מנהל המוסד וחותמת המוסד, וכן דוח מעסיקים מבטוח לאומי – ',
    uhealth: "אישור מרופא משפחה על מצב בריאות תקין.",
    uwork: "אישור נתוני העסקה ממשרד החינוך. (מערכת הפורטל) ",
    upen2: "נספח 2 - קרן פנסיה. ו/או נספח 3 - ביטוח מנהלים. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנוהל הליך הצטרפות לפנסיה/ השתלמות/ גמל)",
    upen4: "נספח 4 - קופת גמל. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)",
    upen5: "נספח 5 - קרן השתלמות. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)",
    uform: "טופס 101. כולל כל מסמך אחר הרלוונטי לצורך חישוב השכר כגון: תאום מס, גמלת ילד נכה וכדו'",
    ubank: "צילום שיק או אישור פרטי ח-ן בנק עדכני.",
    uadvise: "במידה והעובד מיועד לתפקיד יועץ, יש להעלות אישור העסקה מהמפקח על היועצים.",
    udoc: 'במידה והעובד מיועד לתפקיד פרא רפואי, יש להעלות אישור מנהל אגף חנ"מ.',
    upermit: 'במידה והמועמד מועסק כבר כעובד הוראה ומיועד לעבודה כשרת/מזכיר יש לקבל אשור העסקה מהרב יזדי -סמנכ"ל פדגוגיה. וכן להפך.',
    ufamily: "וועדת חריגים-במידה והמועמד מצהיר על קרבת משפחה יש למלא טופס לוועדת חריגים",
    ualready: 'במקרה והמועמד מועסק כבר כעובד עזר ומיועד לעבודה כעובד הוראה יש להעלות אישור העסקה מהרב יזדי – סמנכ"ל פדגוגי.',
    uextra: "כל מסמך נוסף שתרצו להוסיף.",
}

const Checker = () => {
    const [rows, setRows] = useState([]);
    const [rows1, setRows1] = useState([]);
    const [rows2, setRows2] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [pagination, setPagination] = useState([]);
    const [pagination1, setPagination1] = useState([]);
    const [pagination2, setPagination2] = useState([]);
    const [open, setOpen] = useState(false);
    const [sendback, setSendback] = useState(false);
    const [finished, setFinished] = useState(false);
    const [id, setId] = useState(0);
    const [stat, setstat] = useState(4);
    const [edit, setedit] = useState(true);
    const [ALL, setALL] = useState([]);
    const [formdata, setFormdata] = useState({});
    const [hide, setHide] = useState(false);
    const [note, setNote] = useState({
        nsigned: '',
        ntz: '',
        npolice: '',
        narmy: '',
        nhealth: '',
        nwork: '',
        npen2: '',
        npen4: '',
        npen5: '',
        nform: '',
        nbank: '',
        nadvise: '',
        ndoc: '',
        npermit: '',
        nfamily: '',
        nalready: '',
        nextra: '',
    });
    const [checkbox, setCheckbox] = useState({
        nsigned: true,
        ntz: true,
        npolice: true,
        narmy: true,
        nhealth: true,
        nwork: true,
        npen: true,
        nform: true,
        nbank: true,
        npen2: true,
        npen4: true,
        npen5: true,
        nadvise: true,
        ndoc: true,
        npermit: true,
        nfamily: true,
        nalready: true,
        nextra: true,
    });
    const [statText, setstatText] = useState("הבקשה מאושרת");
    const [currURL, setCurrURL] = useState("");
    const [files, setFiles] = useState({});

    const init = () => {
        axios.get("api/checker")
            .then((res) => {
                setPagination(res.data.rows)
                setPagination1(res.data.rowsback)
                setPagination2(res.data.complete)
                setRows2(res.data.complete)
                setRows1(res.data.rowsback)
                setRows(res.data.rows)
                setSpinner(false)
            }).catch((error) => {
                console.error('Error:', error);
            });
    }
    useEffect(() => {
        init();
    }, []);

    useEffect(() => {
        let filenames
        let allFiles = {}
        if (id > 0) {
            axios.post("api/form/upload", { id })
                .then((res) => {
                    if (res.data.msg === 'ok') {
                        filenames = res.data.form
                        let temp = filenames.formtype === 0 ? HORAAH : EZER
                        setALL(temp)
                        async function fetchFile(fileName, name) {
                            await axios.post(`api/uploads`, { fileName })
                                .then((res) => {
                                    const url = res.data.url
                                    allFiles[name] = url;
                                });
                        }
                        async function processArray() {
                            for (const elem of temp) {
                                if (filenames[elem] === '') {
                                    allFiles[elem] = '';
                                } else {
                                    await fetchFile(filenames[elem], elem)
                                }
                            }

                            setFiles(allFiles)
                            setCurrURL(allFiles.usigned);
                        }
                        processArray()

                        let userData = {};
                        let check = {};
                        let notes = {};
                        userData.fname = filenames.fname;
                        userData.lname = filenames.lname;
                        userData.tz = filenames.tz;
                        userData.cell = filenames.cell;
                        userData.mail = filenames.mail;
                        userData.sname = filenames.sname;
                        userData.formtype = filenames.formtype;
                        for (const elem of NOTENAME) {
                            if (filenames[elem] === '') {
                                console.log(filenames[elem]);
                                check[elem] = true
                            } else {
                                check[elem] = false
                            }
                            notes[elem] = filenames[elem]
                        }
                        setCheckbox(check);
                        setNote(notes)
                        setFormdata(userData)
                    }
                }).catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, [id]);
    useEffect(() => {
        console.log(note);
        let nonotes = true;
        let prob = false;
        for (const [key, value] of Object.entries(note)) {
            if (value !== '') {
                nonotes = false;
            }
        }
        for (const [key, value] of Object.entries(checkbox)) {
            if (!value) {
                prob = true;
            }
        }
        if (nonotes && prob) {
            setHide(true)
        } else {
            setHide(false)
        }
        if (nonotes) {
            setstatText("אישור הבקשה")
            setstat(4)
        } else {
            setstatText("נא להשלים את הפרטים שהתבקשו")
            setstat(3)
        }
    }, [note, checkbox]);

    const changeFile = (i) => {
        if (files[i] !== '') {
            setCurrURL(files[i]);
        }
        if (files[i] === currURL && files[i] !== '') {
            openNew()
        }
    }
    const openNew = () => {
        window.open(currURL, '_blank');
    }
    const changeStatus = () => {
        axios.post("api/changestatus", { id, "status": stat })
            .then((res) => {
            }).catch((error) => {
                console.error('Error:', error);
            });
        let date = new Date()
        axios.post("api/checkernote", { id, "note": note })
            .then((res) => {
                if (res.data.msg === 'ok') {
                    setNote({
                        nsigned: '',
                        ntz: '',
                        npolice: '',
                        narmy: '',
                        nhealth: '',
                        nwork: '',
                        npen2: '',
                        npen4: '',
                        npen5: '',
                        nform: '',
                        nbank: '',
                        nadvise: '',
                        ndoc: '',
                        npermit: '',
                        nfamily: '',
                        nalready: '',
                        nextra: '',
                    })
                }
            }).catch((error) => {
                console.error('Error:', error);
            });

        if (stat === 3) {
            let fixed2 = rows.filter(row => row.id === id)
            console.log(fixed2[0].status3date = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2));
            fixed2[0].status3date = date;
            rows1.push(fixed2[0])
            setRows1(rows1)
            setPagination1(rows1)
        }
        if (stat === 4) {
            let fixed3 = rows.filter(row => row.id === id)
            fixed3[0].status4date = date;
            rows2.push(fixed3[0])
            setRows2(rows2)
            setPagination2(rows2)
        }
        let fixed1 = rows.filter(row => row.id !== id)
        setRows(fixed1)
        setPagination(fixed1)

        setId(0);
        setOpen(!open)
    }
    const handleChange = (name, val) => {
        setNote({ ...note, [name]: val })
    }
    const revert = (id) => {
        axios.post("api/changestatus", { id, "status": 2 })
            .then((res) => {
                console.log(res.data)
                init();
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    const changeview = (e, index, id) => {
        e.preventDefault();
        e.stopPropagation();
        const formData = new FormData(e.target);
        let unqid;
        for (let [key, value] of formData.entries()) {
            unqid = value;
        }
        axios.post("api/checkerchangeview", { id, unqid })
            .then((res) => {
                let fixedorigion = [...rows2]
                let orgindex = rows2.findIndex(obj => obj.id === id)
                fixedorigion[orgindex].view = 2
                fixedorigion[orgindex].uniqueworkerid = unqid

                let fixed = [...pagination2]
                fixed[index].view = 2
                fixed[index].uniqueworkerid = unqid
                setRows2(fixedorigion)
                setPagination2(fixed)
            }).catch((error) => {
                console.error('Error:', error);
            });

    }
    const showUploads = (id, edit = 1) => {
        if (edit === 1) {
            setedit(true)
        } else {
            setedit(false)
        }
        if (sendback) {
            setSendback(!sendback)
        }
        if (finished) {
            setFinished(!finished)
        }

        if (id > 0) {
            setId(id);
            setOpen(false)
        } else {
            setOpen(!open)
            setId(0);
        }
        init();
    }
    const showTable = () => {
        if (open) {
            setOpen(!open)
        }
        if (finished) {
            setFinished(!finished)
        }
        setId(0);
        setSendback(!sendback)
        init();
    }
    const showFinished = () => {
        if (open) {
            setOpen(!open)
        }
        if (sendback) {
            setSendback(!sendback)
        }
        setId(0);
        setFinished(!finished)
        init();
    }
    const handleCheck = (name, special) => {
        console.log(name, special);
        setCheckbox({
            ...checkbox,
            [name]: !special
        })
        if (!special) {
            setNote({
                ...note,
                [name]: ''
            })
        }
    }

    // Function to extract specific columns from the data
    const extractSpecificColumns = (data) => {
        return data.map((row) => {
            const { id, fname, lname, tz, formtype, uniqueworkerid, sname, semel, status4date } = row;
            const statusTranslated = formtype === 0 ? "עובד הוראה" : "עובד עזר";
            const date = new Date(status4date)
            const display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
            return {
                "#": id,
                "שם פרטי": fname,
                "שם משפחה": lname,
                "תעודת זהות": tz,
                "סוג טופס": statusTranslated,
                "מספר עובד ייחודי": uniqueworkerid,
                "שם בית הספר": sname,
                "סמל": semel,
                "סטטוס 4 לתאריך": display,
            };
        });
    };

    let specificColumnsData = extractSpecificColumns(pagination2);

    return (
        <>
            <Container>
                <Row className='pt-3 pb-4'>
                    <Col xs={12} md={4} lg={4} xl={4} xxl={4}>
                        <Card role='button' className={open ? 'alert alert-primary shadow p-0 m-0 h-100' : 'shadow-sm h-100'} onClick={() => showUploads(0)}
                            aria-controls="open"
                            aria-expanded={open}>
                            <Card.Title className='m-3'>סטטוס 1 בקשות שהועלו וממתינות לבדיקתי - {spinner ? (<>
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </>) : rows.length}</Card.Title>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} lg={4} xl={4} xxl={4} onClick={() => showTable()}>
                        <Card role='button' className={sendback ? 'alert alert-primary shadow p-0 m-0 h-100' : 'shadow-sm h-100'}>
                            <Card.Title className='m-3'>סטטוס 2 בקשות שהעברתי למוסד להשלמת מסמכים במוסד - {spinner ? (<>
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </>) : rows1.length}</Card.Title>
                        </Card>
                    </Col>
                    <Col xs={12} md={4} lg={4} xl={4} xxl={4} onClick={() => showFinished()}>
                        <Card role='button' className={finished ? 'alert alert-primary shadow p-0 m-0 h-100' : 'shadow-sm h-100'}>
                            <Card.Title className='m-3'>סטטוס 3 בקשות שאישרתי - {spinner ? (<>
                                <Spinner animation="border" role="status" size="sm">
                                    <span className="visually-hidden">Loading...</span>
                                </Spinner>
                            </>) : rows2.length}</Card.Title>
                        </Card>
                    </Col>
                </Row>
                <Collapse in={open}>
                    <div id="open">
                        <Row className='mb-1'>
                            <Col xs={0} md={10} lg={10} xl={10} xxl={10} className=''></Col>
                            <Col xs={12} md={2} lg={2} xl={2} xxl={2} className=''>
                                <Form.Control type="text" placeholder="חיפוש" onChange={(e) => setPagination(rows.filter(i => `${i.fname} ${i.lname}${i.tz}${i.formtype}${i.sname}${i.semel}${i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}`.includes(e.target.value)))} />
                            </Col>
                        </Row>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>תאריך חזרה למוסד</th>
                                    <th>תאריך הגשה</th>
                                    <th>שם</th>
                                    <th>תז</th>
                                    <th>תפקיד</th>
                                    <th>שנה</th>
                                    <th>בית ספר</th>
                                    <th>סוג</th>
                                    <th>סמל</th>
                                    <th>הצג</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pagination.map(i => {
                                    let date = new Date(i.status2date)
                                    let display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
                                    let date1 = new Date(i.status3date)
                                    let display1 = date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear() + ' ' + ('0' + date1.getHours()).slice(-2) + ':' + ('0' + date1.getMinutes()).slice(-2);
                                    return (
                                        <tr key={i.id}>
                                            <td>{display1 === "1/1/1970 02:00" ? "הגשה ראשונית" : display1}</td>
                                            <td>{display}</td>
                                            <td>{i.fname + ' ' + i.lname}</td>
                                            <td>{i.tz}</td>
                                            <td>{i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                                            <td>{i.year}</td>
                                            <td title={i.sname}>{i.sname}</td>
                                            <td>{i.Mtype}</td>
                                            <td>{i.semel}</td>
                                            <td className='text-center' onClick={() => showUploads(i.id, 1)}><svg role="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                            </svg></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table>
                    </div>
                </Collapse>
                <Collapse in={sendback}>
                    <div id="sendback">
                        <Row className='mb-1'>
                            <Col xs={0} md={10} lg={10} xl={10} xxl={10} className=''></Col>
                            <Col xs={12} md={2} lg={2} xl={2} xxl={2} className=''>
                                <Form.Control type="text" placeholder="חיפוש" onChange={(e) => setPagination1(rows1.filter(i => `${i.fname} ${i.lname}${i.tz}${i.formtype}${i.sname}${i.semel}${i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}`.includes(e.target.value)))} />
                            </Col>
                        </Row>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>תאריך הגשה</th>
                                    <th>תאריך חזרה למוסד</th>
                                    <th>שם</th>
                                    <th>תז</th>
                                    <th>תפקיד</th>
                                    <th>שנה</th>
                                    <th>בית ספר</th>
                                    <th>סמל</th>
                                    <th>הצג</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pagination1.map(i => {
                                    let date = new Date(i.status3date)
                                    let display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
                                    let date1 = new Date(i.status2date)
                                    let display1 = date1.getDate() + '/' + (date1.getMonth() + 1) + '/' + date1.getFullYear() + ' ' + ('0' + date1.getHours()).slice(-2) + ':' + ('0' + date1.getMinutes()).slice(-2);

                                    return (
                                        <tr key={i.id}>
                                            <td>{display1}</td>
                                            <td>{display}</td>
                                            <td>{i.fname + ' ' + i.lname}</td>
                                            <td>{i.tz}</td>
                                            <td>{i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                                            <td>{i.year}</td>
                                            <td title={i.sname}>{i.sname}</td>
                                            <td>{i.semel}</td>
                                            <td className='text-center' onClick={() => showUploads(i.id, 2)}><svg role="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                            </svg></td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </div>
                </Collapse>
                <Collapse in={finished}>
                    <div id="finished">

                        <Row className='mb-1'>
                            <Col xs={0} md={8} lg={9} xl={9} xxl={9} className='p-0'></Col>
                            <Col xs={12} md={2} lg={2} xl={2} xxl={2} className='p-0'>
                                <Form.Control type="text" placeholder="חיפוש" onChange={(e) => setPagination2(rows2.filter(i => `${i.fname} ${i.lname}${i.tz}${i.formtype}${i.sname}${i.uniqueworkerid}${i.semel}${i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}`.includes(e.target.value)))} />
                            </Col>
                            <Col xs={12} md={2} lg={1} xl={1} xxl={1} className='pe-0'><TableToExcel data={specificColumnsData} fileName={`data_${getFormattedDate()}`} /></Col>

                        </Row>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>תאריך סטטוס 4</th>
                                    <th>שם</th>
                                    <th>תז</th>
                                    <th>תפקיד</th>
                                    <th>שנה</th>
                                    <th>בית ספר</th>
                                    <th>סוג</th>
                                    <th>סמל</th>
                                    <th>הצג</th>
                                    <th>גישה למחלקות</th>
                                    <th>מספר עובד</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pagination2.map((i, index) => {
                                    let date = new Date(i.status4date)
                                    let display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
                                    return (
                                        <tr key={i.id}>
                                            <td>{display}</td>
                                            <td>{i.fname + ' ' + i.lname}</td>
                                            <td>{i.tz}</td>
                                            <td>{i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                                            <td>{i.year}</td>
                                            <td title={i.sname}>{i.sname.length > 13 ? i.sname.substring(0, 13) + "..." : i.sname}</td>
                                            <td>{i.Mtype}</td>
                                            <td>{i.semel}</td>
                                            <td className='text-center' onClick={() => showUploads(i.id, 2)}><svg role="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                                <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                            </svg></td>
                                            <td className='text-center'>
                                                {i.view === 1 ? (
                                                    <Form onSubmit={(e) => changeview(e, index, i.id)}>
                                                        <InputGroup className="" dir='ltr'>
                                                            <Button type="submit" variant="outline-primary">
                                                                פתיחת גישה
                                                            </Button>
                                                            <Form.Control dir='rtl' required className='inpwidth' autoComplete="off" name="workid" type="number" placeholder="מס' עובד"
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                ) : (
                                                    <Form onSubmit={(e) => changeview(e, index, i.id)}>
                                                        <InputGroup className="" dir='ltr'>
                                                            <Button type="submit" variant="outline-danger">
                                                                עדכן
                                                            </Button>
                                                            <Form.Control dir='rtl' required className='inpwidth' autoComplete="off" name="workid" type="number" placeholder="מס' עובד"
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                )}

                                            </td>
                                            <td>{
                                                i.uniqueworkerid ? i.uniqueworkerid :
                                                    <Badge bg="danger" role="button" onClick={() => revert(i.id)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-backspace-reverse-fill" viewBox="0 0 16 16">
                                                            <path d="M0 3a2 2 0 0 1 2-2h7.08a2 2 0 0 1 1.519.698l4.843 5.651a1 1 0 0 1 0 1.302L10.6 14.3a2 2 0 0 1-1.52.7H2a2 2 0 0 1-2-2V3zm9.854 2.854a.5.5 0 0 0-.708-.708L7 7.293 4.854 5.146a.5.5 0 1 0-.708.708L6.293 8l-2.147 2.146a.5.5 0 0 0 .708.708L7 8.707l2.146 2.147a.5.5 0 0 0 .708-.708L7.707 8l2.147-2.146z" />
                                                        </svg>
                                                    </Badge>
                                            }
                                            </td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </div>
                </Collapse>
                {(id > 0) ? (
                    <>
                        <span><p><b>שם: {formdata.fname + " " + formdata.lname} תז: {formdata.tz} טל: {formdata.cell} מייל: {formdata.mail} מועמד לבית ספר {formdata.sname} כ{formdata.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'} בשנת {formdata.year}</b></p></span>
                        <Row className='mb-2'>
                            <Col xs={12} md={4} lg={4} xl={4} xxl={4} className='scroll'>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>תקין</th>
                                            <th>לחץ לפתיחת קובץ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ALL.map((item, i) => (
                                            <React.Fragment key={item}>
                                                <tr>
                                                    <td className='text-center'>
                                                        <Form.Check type="checkbox" name={"n" + item.slice(1)} disabled={!edit} onChange={(e) => handleCheck(e.target.name, checkbox["n" + item.slice(1)])} checked={note["n" + item.slice(1)] === '' && checkbox["n" + item.slice(1)]} />
                                                    </td>
                                                    <td onClick={() => changeFile(item)}>
                                                        <p className="text-end">{`${i + 1}) ${HEBREW[item]} `}<i>{files[item] === '' ? 'לא הועלה קובץ' : ''}</i></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2} className={(note["n" + item.slice(1)] === '' && checkbox["n" + item.slice(1)]) ? "d-none" : ''}>
                                                        <Form.Group className="" controlId={note["n" + item.slice(1)]}>
                                                            <Form.Control type="text" value={note["n" + item.slice(1)]} disabled={!edit} name={"n" + item.slice(1)} onChange={(e) => handleChange(e.target.name, e.target.value)} placeholder="הערה" />
                                                        </Form.Group>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={8} lg={8} xl={8} xxl={8}><UFiles key={currURL} url={currURL} /></Col>
                        </Row>
                        <Button className={hide ? 'invisible mb-2' : "mb-2"} variant={stat === 3 ? "danger" : "warning"} disabled={!edit} onClick={changeStatus}>{statText}</Button>
                    </>
                ) : (<></>)
                }
                {/*<hr />
                <div className='text-center'>
                    <Button variant='primary'>הוספת משתמש</Button>
            </div>*/}
            </Container>
        </>
    )
}
export default Checker